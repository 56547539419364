/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import calendarService from '@/services/calendarService'
import date_data from '@/json/date.json'
import { empty_guid, meetings, placement_meeting_types } from '@/json/data.json'
import { translationMixin } from '@/mixins/translationMixin'

export const calendarMixin = {
  mixins: [translationMixin],
  data() {
    return {
      calTranslations: {} //  calendar translations, named to avoid naming collisions
    }
  },
  methods: {
    ...mapActions({}),
    $_getOrdinalDay(day) {
      if (this.calTranslations) {
        const { tcFirst, tcSecond, tcThird, tcFourth, tcLast } = this.calTranslations
        return ['', tcFirst, tcSecond, tcThird, tcFourth, tcLast][day]
      }
      return ['', 'First', 'Second', 'Third', 'Fourth', 'Last'][day]
    },
    $_getDayOfWeek(item) {
      return this.weekdays.filter(x => x.value === parseInt(item))[0].text
    },
    $_recurEveryXWeeksWithWeekDay(timeAmount, incomingDays) {
      // IE, (2, 'Monday') -or- (1, ['Saturday', 'Sunday'])
      const { tcEvery, tcWeeks } = this.calTranslations
      const daysOfWeek = incomingDays instanceof Array ? incomingDays : [incomingDays] // convert to array, if needed
      let days = daysOfWeek.map(day => {
        const dayOfWeek = this.convertValForTranslation(day)
        return this.calTranslations[dayOfWeek] ? this.calTranslations[dayOfWeek] : day
      })
      return `${tcEvery} ${timeAmount} ${tcWeeks} ${days.join(',')}`.trim() // `Every X week(s) Monday, Thursday`
    },
    printDate(num) {
      let dateEpoch = new Date(0)
      dateEpoch.setUTCSeconds(Math.floor(num / 1000))
      var options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'long'
      }
      return dateEpoch.toLocaleDateString('en-US', options)
    },
    properStartEndTimes(startTime, endTime) {
      let pass = true
      try {
        let s_time_array = startTime.split(':')
        let e_time_array = endTime.split(':')
        let start_hour = parseInt(s_time_array[0])
        let end_hour = parseInt(e_time_array[0])
        let start_minute = parseInt(s_time_array[1].substring(0, 2))
        let end_minute = parseInt(e_time_array[1].substring(0, 2))
        let start_ampm = s_time_array[1].substring(2)
        let end_ampm = e_time_array[1].substring(2)
        if (start_ampm === end_ampm) {
          pass =
            (startTime === endTime && startTime !== '12:00AM') ||
            start_hour < end_hour ||
            (start_hour === end_hour && start_minute < end_minute)
        } else {
          pass = start_ampm + end_ampm !== 'PMAM'
        }
        return pass
      } catch (e) {
        console.error(e)
        return false
      }
    },
    combineStartEnd(startDate, endDate) {
      let timeString = endDate.substring(endDate.indexOf('T') + 1)
      let dateString = startDate.substring(0, startDate.indexOf('T'))
      if (timeString == '00:00:00') {
        return dateString + 'T' + '00:30:00'
      } else {
        return dateString + 'T' + timeString
      }
    },
    meetingExpression(recurrence, incomingTranslations) {
      if (!recurrence || !incomingTranslations) return ''
      this.calTranslations = incomingTranslations
      const tcDay = this.calTranslations.tcDay // 'Day'
      const tcDays = this.calTranslations.tcDays // 'Days'
      const tcEvery = this.calTranslations.tcEvery // 'Every'
      const tcEveryDay = this.calTranslations.tcEveryDay // 'Every Day'
      const tcMonth = this.calTranslations.tcMonth // 'Month'
      const tcMonths = this.calTranslations.tcMonths // 'month(s)'
      const tcOnce = this.calTranslations.tcOnce // 'Once'
      const tcWeeks = this.calTranslations.tcWeeks // 'week(s)'
      const tcYears = this.calTranslations.tcYears // 'year(s)'

      switch (recurrence.vmrt_trn_txt) {
        case 'Weekly':
          let _weekdays = !!recurrence.mtr_recurrence_specific_days_of_week
            ? recurrence.mtr_recurrence_specific_days_of_week.split(',').map(item => {
                return this.$_getDayOfWeek(item)
              })
            : []
          return this.$_recurEveryXWeeksWithWeekDay(recurrence.mtr_recurrence_day_of_week_or_month, _weekdays)
        case 'Daily':
          if (recurrence.mtr_recurrence_frequency === 1) {
            return tcEveryDay
          } else {
            return `${tcEvery} ${recurrence.mtr_recurrence_frequency} ${tcDays}`
          }
        case 'Once':
          return tcOnce
        case 'Monthly':
          let retMonthly = ''

          if (recurrence.mtr_option === 'type_1') {
            retMonthly += `${tcDay} ${recurrence.mtr_recurrence_frequency} ` // 'Day 3'
          } else {
            retMonthly += `${this.$_getOrdinalDay(recurrence.mtr_recurrence_frequency)} ` // `Second`, `Third`, ...
          }
          if (!!recurrence.mtr_recurrence_day_of_week_or_month) {
            let dayOfWeek = this.$_getDayOfWeek(recurrence.mtr_recurrence_day_of_week_or_month) // 'Monday'
            const tempDay = this.convertValForTranslation(dayOfWeek) // 'tcMonday'
            dayOfWeek = this.calTranslations[tempDay] ? this.calTranslations[tempDay] : dayOfWeek
            retMonthly += ` ${dayOfWeek}`
          }
          retMonthly += ` - ${tcEvery} ${recurrence.mtr_recurrence_month_frequency} ${tcMonths}`
          return retMonthly
        case 'Yearly':
          let rtnYearly = ''
          rtnYearly += `${tcEvery} ${recurrence.mtr_recurrence_year_frequency} ${tcYears} `
          if (recurrence.mtr_option === 'type_1') {
            rtnYearly += `- ${tcMonth} ${recurrence.mtr_recurrence_month_frequency} `
            rtnYearly += `- ${tcDay} ${recurrence.mtr_recurrence_frequency} `
          } else {
            rtnYearly += `- ${tcEvery} ${recurrence.mtr_recurrence_month_frequency} ${tcMonths} `
            rtnYearly += `- ${tcEvery} ${recurrence.mtr_recurrence_frequency} ${tcWeeks} `
          }
          if (!!recurrence.mtr_recurrence_day_of_week_or_month) {
            rtnYearly += ` ${this.$_getDayOfWeek(recurrence.mtr_recurrence_day_of_week_or_month)}`
          }
          return rtnYearly
      }
    },
    meetingFrequencyText(recurrence, incomingTranslations) {
      this.calTranslations = incomingTranslations ? incomingTranslations : {}
      const recurrenceTypeText = !recurrence ? '' : recurrence.vmrt_trn_txt

      switch (recurrenceTypeText) {
        case 'Daily':
          return this.calTranslations.tcDailyMeeting
        case 'Monthly':
          return this.calTranslations.tcMonthlyMeeting
        case 'Once':
          return this.calTranslations.tcOnceMeeting
        case 'Weekly':
          return this.calTranslations.tcWeeklyMeeting
        case 'Yearly':
          return this.calTranslations.tcYearlyMeeting
        default:
          return ''
      }
    },
    formatForDatePicker(date, returnType = 'string') {
      if (!date) return false
      let formattedDate
      let newDate
      try {
        if (typeof date === 'string') {
          if (date.includes(' ') || date.includes('-')) {
            const parts = date.includes(' ') ? date.split(' ') : date.split('-')
            if (date_data.dow_abbr.includes(parts[0])) {
              const mn = parseInt(date_data.months.find(dm => dm.abbrlow === parts[1]).value, 10) - 1
              return new Date(parseInt(parts[3], 10), mn, parseInt(parts[2], 10), 1, 2, 3)
            } else {
              newDate = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10), 1, 2, 3)
              if (returnType === 'string') {
                let stringDate = `${newDate.getFullYear()}-${(newDate.getMonth() + 1 < 10 ? '0' : '') +
                  (newDate.getMonth() + 1)}-${(newDate.getDate() < 10 ? '0' : '') + newDate.getDate()}`
                return stringDate
              }
              return newDate
            }
          } else {
            formattedDate = date.substring(0, 10)
          }
        } else {
          formattedDate = date.toISOString('en-US')
        }
        newDate = new Date(formattedDate.substring(0, 10) + 'T01:02:03')
        newDate = `${newDate.getFullYear()}-${(newDate.getMonth() + 1 < 10 ? '0' : '') +
          (newDate.getMonth() + 1)}-${(newDate.getDate() < 10 ? '0' : '') + newDate.getDate()}`
      } catch (error) {
        newDate = null
        console.error(error)
      }
      return newDate
    },
    meetingDetails(item) {
      return {
        color: item.color,
        date: new Date(item.mtg_start_date).getTime(),
        description: item.location,
        distribution: item.distribution,
        distributionSelected: [],
        end: !!item.start_end_combine
          ? this.combineStartEnd(item.mtg_start_date_time, item.mtg_end_date_time)
          : this.printDate(new Date(item.mtg_end_date).getTime()),
        eTime: item.mtg_mtt_key == meetings.stateconvention.value ? null : item.mtg_end_time,
        eventname:
          (item.mtg_title_month && (item.mtg_title_month.includes('<br') ? item.mtg_title : item.mtg_title_month)) ||
          item.mtg_title,
        hostname: item.hostname,
        id: item.mtg_key,
        label: null,
        location: {
          adr_city: item.loc_adr_city,
          adr_line1: item.loc_adr_line1,
          adr_line2: item.loc_adr_line2,
          adr_line3: item.loc_adr_line3,
          adr_postal_code: item.loc_adr_postal_code,
          adr_state: item.loc_adr_state,
          org_name: item.location
        },
        locationVirtual: {
          lvt_key: item.lvt_key,
          lvt_name: item.lvt_name,
          lvt_url: item.lvt_url,
          lvt_note: item.lvt_note,
          lvt_expiration_date: item.lvt_expiration_date
        },
        meetingtitle: item.mtt_description_text,
        meetingtype: item.mtg_mtt_key,
        mtgkey: item.mtg_key,
        mtg_coordinator_ind_key: item.mtg_coordinator_ind_key,
        mtg_end_date: item.mtg_end_date,
        mtg_location_org_key: item.mtg_location_org_key,
        mtg_mpt_key: item.mtg_mpt_key,
        mtg_mtt_key: item.mtg_mtt_key,
        mtg_start_date: item.mtg_start_date,
        mtg_title: item.mtg_title,
        mtrkey: item.mtg_mtr_key,
        reccurrencetype: item.meetingRec || null,
        scope: item.scope,
        start: item.mtg_start_date_time,
        startdate: this.printDate(new Date(item.mtg_start_date).getTime()),
        startendtime: `${item.mtg_start_time} - ${item.mtg_end_time}`,
        sTime: item.mtg_mtt_key == meetings.stateconvention.value ? null : item.mtg_start_time,
        title: item.mtt_description_text,
        vip_email: item.vip_email,
        vip_formal_name: item.vip_formal_name,
        vip_phone: item.vip_phone,
        vmpt_trn_txt: item.vmpt_trn_txt,
        vmrt_trn_txt: item.vmrt_trn_txt,
        mtg_distribution_placement_key: item.mtg_distribution_placement_key,
        mtg_category_key: item.mtg_category_key
      }
    },
    async storePlacementKeyAndRedirect(item) {
      // If the placement facility key is populated
      if (
        (!!item.plf_org_key && placement_meeting_types.includes(item.mtg_mtt_key)) ||
        (!!item.destination && item.destination.includes('placement-details'))
      ) {
        await this.setSelectedLocationKey(item.plf_org_key)
        await this.setSelectedPlacementDateKey(item.mtg_key)
        this.$router.push({ name: 'placement-details' })
      } else {
        // When rendering auxiliary events, always link to the calendar event.
        if (!!item.mtg_key) {
          await this.setSelectedVisitKey(item.mtg_key)
        }
        let result = {}
        let meetingRec = {}
        let events = {}
        calendarService
          .getMeetingSingle(item.mtg_host_org_key || this.officerToolbarSelected.camp, item.mtg_key)
          .then(async res => {
            if (res.status === 200) {
              result = res.data
              // If the meeting is recurring, retrieve the recurrence structure before
              // navigating to the event details.
              if (!!res.data.meetings[0].mtg_mtr_key && res.data.meetings[0].mtg_mtr_key !== empty_guid) {
                await calendarService.getMeetingRecurrence(res.data.meetings[0].mtg_mtr_key).then(res => {
                  if (res.status === 200) {
                    meetingRec = res.data
                    events = this.getEvent(result, meetingRec).then(evt => {
                      this.clearSetAndGo(evt[0])
                    })
                  }
                })
              } else {
                // Meeting is not recurring. No additional information is needed before
                // navigating to the event details.
                events = await this.getEvent(result, undefined).then(evt => {
                  this.clearSetAndGo(evt[0])
                })
              }
            }
          })
          .catch(error => {
            console.error('error: ', error)
          })
      }
    },
    clearSetAndGo(evt) {
      this.clearSelectedEvent()
      this.setSelectedMeeting(evt)
      this.$router.push({ name: 'cal-event-detail' })
      return
    },
    async getEvent(result, meetingRec) {
      return result.meetings.map(item => {
        item.meetingRec = meetingRec
        item.color =
          item.vmpt_trn_txt === 'Gideon'
            ? 'card-Gideon'
            : item.vmpt_trn_txt === 'Auxiliary'
            ? 'card-Auxillary'
            : 'card-Both'
        return this.meetingDetails(item)
      })
    }
  },
  computed: {
    ...mapGetters({}),
    weekdays() {
      return date_data.days.map(dd => {
        return { value: dd.weekday, text: dd.text }
      })
    }
  }
}
